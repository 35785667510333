import React, { useState } from 'react';
import './Probing.css'; // Import the CSS file
import excelData from './excel.json'; // Import the JSON file

const Probing = () => {
  const [selectedTable, setSelectedTable] = useState(null);

  // Create a mapping from the Index titles to the actual JSON keys
  const tableMapping = {
    "General Probing": "General Probing",
    "AC Adapter not Working": "AC Adapter not Working",
    "Audio Distortion": "Audio Distortion",
    "Battery Not Charging": "Battery Not Charging",
    "BSOD": "BSOD",
    "Intermittent Shutdown": "Intermittent Shutdown",
    "Keyboard not Working ": "Keyboard not Working",
    "LCD Dim Issues": "LCD Dim Issues",
    "Mouse & Touchpad": "Mouse & Touchpad",
    "No Boot": "No Boot",
    "No Post": "No Post",
    "No Power": "No Power",
    "Noise Issues ": "Noise Issues",
    "Automatic Restarts": "Automatic Restarts",
    "Slow Performance": "Slow Performance",
    "Sound but No Video": "Sound but No Video",
    "Video but no Sound": "Video but no Sound",
    "System Overheating": "System Overheating",
    "USB Devices not working": "USB Devices not working",
    "Wireless Keyboard and Mouse": "Wireless Keyboard and Mouse",
    "Wrong Characters Displayed": "Wrong Characters Displayed"
  };

  const tableTitles = excelData.Index.map(item => item['Column10']);

  const renderTableData = (tableName) => {
    const jsonKey = tableMapping[tableName];
    const tableData = excelData[jsonKey];

    if (!tableData) {
      return <p>No data available for {tableName}</p>;
    }

    return (
      <div>
        <h2>{tableName}</h2>
        <ul>
          {tableData.map((item, index) => (
            <li key={index}>
              {Object.entries(item).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}</strong>: {value}
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="probing">
      <h1>Basic Probing </h1>
      <div className="probing-content"> {/* Wrapper for the content area */}
        {selectedTable === null ? (
          <div>
            <h2>Select the Issue type </h2>
            {tableTitles.map((title, index) => (
              <button key={index} onClick={() => setSelectedTable(title)}>
                {title}
              </button>
            ))}
          </div>
        ) : (
          <div>
            <button onClick={() => setSelectedTable(null)}>Back to Titles</button>
            {renderTableData(selectedTable)}
          </div>
        )}
      </div> {/* End of .probing-content */}
    </div>
  );
};

export default Probing;
