import React, { useState } from "react";
import "./FeedbackForm.css";

const FeedbackForm = () => {
  const [caseNumber, setCaseNumber] = useState("");
  const [caseDetails, setCaseDetails] = useState(""); // Updated state variable for case details
  const [issueType, setIssueType] = useState("");
  const [feedbackType, setFeedbackType] = useState(""); // State for feedback type
  const [feedback, setFeedback] = useState("");
  const [selectedTE, setSelectedTE] = useState(""); // Updated for single TE selection
  const [selectedMOD, setSelectedMOD] = useState(""); // State for MOD selection
  const [selectedManager, setSelectedManager] = useState(""); // State for Manager selection

  // Replace with your full list of technical experts
  const technicalExperts = ["Sajid", "TE2", "TE3", "TE4", "TE5", "TE6", "TE7", "TE8", "TE9", "TE10"]; // Add all 58 TEs here

  // Replace with your full list of issues
  const issues = [
    "BSOD",
    "No Power",
    "No Video",
    "Keyboard Issue",
    "Overheating",
    "Software Issue",
    "Network Issue",
    // Add all 65 issues here
  ];

  // List of MODs and Managers
  const mods = ["MOD 01", "MOD 02", "MOD 03", "MOD 04"];
  const managers = ["Manager 01", "Manager 02", "Manager 03", "Manager 04"];

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to send feedback (to be implemented)
    console.log({
      caseNumber,
      caseDetails, // Use caseDetails instead of customerDetails
      issueType,
      feedback,
      selectedTE,
      feedbackType, // Log the selected feedback type
      selectedMOD, // Log the selected MOD
      selectedManager, // Log the selected Manager
    });
  };

  return (
    <form className="feedback-form" onSubmit={handleSubmit}>
      <h2>Send Feedback</h2>
      <div>
        <label>Select Feedback Type</label>
        <select
          value={feedbackType}
          onChange={(e) => setFeedbackType(e.target.value)}
          required
        >
          <option value="">Please select an option</option>
          <option value="DSAT">DSAT</option>
          <option value="FDR">FDR</option>
          <option value="Option 3">Option 3</option>
          <option value="Option 4">Option 4</option>
          <option value="Option 5">Option 5</option>
        </select>
      </div>
      <div>
        <label>Case Number</label>
        <input
          type="text"
          value={caseNumber}
          onChange={(e) => setCaseNumber(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Case Details</label> {/* Updated label */}
        <textarea
          value={caseDetails} // Updated to use caseDetails
          onChange={(e) => setCaseDetails(e.target.value)} // Updated to set caseDetails
          required
        />
      </div>
      <div>
        <label>Issue Type</label>
        <select
          value={issueType}
          onChange={(e) => setIssueType(e.target.value)}
          required
        >
          <option value="">Select Issue</option>
          {issues.map((issue) => (
            <option key={issue} value={issue}>
              {issue}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Feedback</label>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Select Technical Expert</label>
        <select
          value={selectedTE}
          onChange={(e) => setSelectedTE(e.target.value)}
          required
        >
          <option value="">Select Technical Expert</option>
          {technicalExperts.map((te) => (
            <option key={te} value={te}>
              {te}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Select MOD</label>
        <select
          value={selectedMOD}
          onChange={(e) => setSelectedMOD(e.target.value)}
          required
        >
          <option value="">Select MOD</option>
          {mods.map((mod) => (
            <option key={mod} value={mod}>
              {mod}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Select Manager</label>
        <select
          value={selectedManager}
          onChange={(e) => setSelectedManager(e.target.value)}
          required
        >
          <option value="">Select Manager</option>
          {managers.map((manager) => (
            <option key={manager} value={manager}>
              {manager}
            </option>
          ))}
        </select>
      </div>
      <button type="submit">Send Feedback</button>
    </form>
  );
};

export default FeedbackForm;
