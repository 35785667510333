import React from 'react';
import { Link } from 'react-router-dom';
import './Item.css';
import Button from 'react-bootstrap/Button';

const Item = ({ ele, index }) => {
  const iss = ele['issues'].replaceAll('_', ' ');

  return (
    <>
      <div className="list_item">
        <div className="sno_item">
          <p>{index + 1}</p>
        </div>
        <div className="user_item">
          <p>{ele['user']}</p>
        </div>
        <div className="case_item">
          <p>{ele['case']}</p>
        </div>
        <div className="issue_item">
          <p>{iss}</p>
        </div>

        <div className="view_item">
          <Button as={Link} to={`/dashboard/details/${ele['case']}`} variant="dark" size="sm">
            View Details
          </Button>
        </div>
      </div>

      <hr className="under" />
    </>
  );
};

export default Item;
