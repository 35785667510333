import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import Form from 'react-bootstrap/Form'
import Item from './Item'
import Button from 'react-bootstrap/Button';
const Dashboard = ({setDisplay})=> {
  const [searchValue,setSearchValue] = useState('')
  const [searchData,setSearchData] = useState([])
  const [searchResults,setSearchResults] = useState([])
  const [item,setItem] = useState('Please Wait...')
  const [filterValue,setFilterValue] = useState('')
  const [error,setError] = useState('')
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(()=>{
    setDisplay('block')
  },[])
  useEffect(()=>{
    const result = async()=>{
      try{
        const response = await fetch(`https://blitzpath-gtc.el.r.appspot.com/getAllTroubleshooting`)
        if(response.ok){

          const resObj = await response.json()


          const data = resObj;

          setSearchData(JSON.parse(data))
          setSearchResults(JSON.parse(data))
        }
      }
      catch(error){
        setError(error)
      }
    }
    result()
  },[])
  useEffect(()=>{
    handleFilter(filterValue)
  },[filterValue,searchValue])
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handleFilter = (filterValue) => {
    if (searchValue === '' && filterValue === '') {
      setSearchResults(searchData);
    } else {
      const filteredData = searchData.filter((item) => {
        
        if(searchValue !== ''){
          if(filterValue !== ''){
            return item.issues.replaceAll('_',' ').includes(filterValue) && (item.case.toLowerCase().includes(searchValue.toLowerCase()));
          }
          else{
          return item.case.toLowerCase().includes(searchValue.toLowerCase()) || item.issues.replaceAll('_',' ').toLowerCase().includes(searchValue.toLowerCase());
          }
        }
        return item.issues.replaceAll('_',' ').includes(filterValue)
      });
      if(filteredData.length === 0){
        setItem('Oops! No data found')
      }
      else{
        setItem('Please Wait...')
      }
      setSearchResults(filteredData);
    }
  };

  const handleChange = (e)=>{
    // setSearchValue(e.target.value)

  }
  const handleFilterChange = (e)=>{
    if(e.target.value !== 'select')
    {
      setFilterValue(e.target.value)
    }
    
    
}
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  // Calculate the start and end index of the items to show on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return (
    <div className='admin'>
        <div className="filter">
            <div className="bar">
            <div className="search">
                {/* <input type="text" placeholder='Search...' onChange={handleChange}/> */}
                <input type="search" required placeholder='Search...' onChange={handleChange}/>
                <div className='search_icon'>
                  <i class="fa fa-search"></i>
                </div>
            </div>
            <div className='searchFilter'>
            <label>Filter by :</label>
            <Form.Select size="lg" onChange={handleFilterChange}  style={{backgroundColor: "#e5e5e5"}} id="issuesSelect">
                
                <option defaultValue>select</option>
                <option>No power</option>
                <option>No power Desktop</option>
                <option>No post</option>
                <option>No Boot</option>
                <option>AC Adapter</option>
                <option>Slow Performance</option>
                <option>Microphone Issue</option>
                <option>No Display</option>
                <option>No Audio</option>
                <option>Fingerprint Reader Issue</option>
                <option>Heating Issue</option>
                <option>Touchpad Issue</option>
                <option>Fan Issue</option>
                <option>Mouse Issue</option>
                <option>Battery Swollen Issue</option>
                <option>Battery Not Charging</option>
                <option>Battery Discharging Quickly</option>
                <option>Camera Issue</option>
                <option>Bluetooth Issue</option>
                <option>Touchscreen Issue</option>
                <option>Internal Keyboard Issue</option>
                <option>Bitlocker Issue</option>
                {/* <option>Touchpad not working</option>
                <option>Black screen issue</option>
                <option>Overheating</option> */}
              </Form.Select>
            </div>
            </div>
        </div>
        <hr />
        <div className="list">
            <div className="item">
              <div className="sno">  
              <h3>SNo.</h3>
              </div>
              <div className="user">
                <h3>Username</h3>
              </div>
              <div className="case">
                <h3>Id</h3>
              </div>
              <div className="issue">
                <h3>Issue</h3>
              </div>
              <div className='view'></div>
            </div>
          {error === ''?<>  {searchResults.length !==0 ?searchResults.slice(startIndex, endIndex).map((ele,index)=>{
              return(
                <Item ele={ele} key={index} index = {startIndex+index} />
              )
            }): <p>{item}</p>}
             <div className='next'>
             <Button
  style={{ backgroundColor: "#1f2325", borderColor: "#1f2325", color: "white" }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = "#ced4da";
    e.target.style.color = "#1f2325";
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = "#1f2325";
    e.target.style.color = "white";
  }}
  onClick={handlePrevPage} disabled={currentPage > 1 && item !== 'Oops! No data found'? false:true}
>
Back
</Button>
             <Button
  style={{ backgroundColor: "#1f2325", borderColor: "#1f2325", color: "white" }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = "#ced4da";
    e.target.style.color = "#1f2325";
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = "#1f2325";
    e.target.style.color = "white";
  }}
  onClick={handleNextPage} disabled={searchResults.length > endIndex && item !== 'Oops! No data found'?false:true}
>
Next
</Button>
        {/* { <button onClick={handlePrevPage} disabled={currentPage > 1 && item !== 'Oops! No data found'? false:true}>Previous</button>} */}
        {/* {<button onClick={handleNextPage} disabled={searchResults.length > endIndex && item !== 'Oops! No data found'?false:true}>Next</button>} */}
      </div></>:<p>{error}</p>}
            
            
        </div>
    </div>
  )
}
export default Dashboard