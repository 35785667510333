import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
// import Item from './Item'
import { Button, Table } from "react-bootstrap";
import axios from "axios";

const RequestSignUp = ({ setDisplay }) => {
  const [error, setError] = useState("");
  const [status, setStatus] = useState("pending");
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [requestData, setRequestData] = useState({});
  const [apiResponse, setApiResponse] = useState(null);
  const [action, setAction] = useState("");
  const [username, setUsername] = useState({});

  useEffect(() => {
    setDisplay("block");
  }, []);

  const handleApproval = (value, name) => {
    setAction(value);
    setUsername(name);
  };

  const handleRejection = (value, name) => {
    setAction(value);
    setUsername(name);
  };

  // Function to handle changes in the select input
  const handleSelectChange = (event) => {
    //  console.log(event.target.value,"answer..l;;'")
    setSelectedOption(event.target.value);
    setStatus(event.target.value);
  };

  useEffect(() => {
    const result = async (status) => {
      try {
        // console.log(status,"present here....")
        const response = await fetch(
          `https://blitzpath-gtc.el.r.appspot.com/user/fetchApprovals/${status}`
        );
        if (response.ok) {
          const resObj = await response.json();
          setData(resObj.data);
          console.log(data, "etydghfhg......");
        }
      } catch (error) {
        setError(error);
      }
    };

    result(status);
  }, [status]);

  // Function to handle the PUT request
  useEffect(() => {
    const handlePutRequest = async (action, username) => {
      try {
        console.log(action, "keyword...");

        console.log(username, "username as ID in stringify..;;'");
        // Make a PUT request to your API with the data from the state
        const response = await axios.put(
          `https://blitzpath-gtc.el.r.appspot.com/user/approval/${action}`,
          { username: username }
        );
        // Handle the API response as needed
        setApiResponse(response.data);
        window.location.reload(false);
        console.log(apiResponse, "ghsahdgk...");
      } catch (error) {
        console.error("Error making PUT request:", error);
      }
    };
    handlePutRequest(action, username);
  }, [action, username]);

  return (
    <div>
      <div>
        {/* List of request */}
        {/* <Button onClick={()=> handleClick('pending')}>Pending</Button>  
            <Button onClick={()=> handleClick('approved')}>Approved</Button>    */}
      </div>

      <div className="form-group">
        <label>Select an option:</label>
        <select
          className="form-control"
          id="exampleSelect"
          value={selectedOption}
          onChange={handleSelectChange}
        >
          <option value="">Select....</option>
          {/* <option value="all">All requests</option> */}
          <option value="pending">Pending requested</option>
          {/* <option value="rejected">Rejected requested</option> */}
          {/* <option value="option3">Option 3</option> */}
        </select>
      </div>

      <div>
        <h2>Sign Up Request</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Serial No.</th>
              <th>Username</th>
              <th>Role</th>
              <th>Approve User Request</th>
              <th>Reject User Request</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}.</td>
                <td>{item.username}</td>
                <td>{item.role}</td>
                <td>
                  <Button
                    onClick={() => handleApproval("approve", item.username)}
                  >
                    Approve
                  </Button>
                </td>
                <td>
                  <Button
                    onClick={() => handleRejection("reject", item.username)}
                  >
                    Reject
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RequestSignUp;
