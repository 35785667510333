import React, { useEffect } from "react";
import Condition from "./Condition/Condition";
import "./Second.css";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import copy from "../../image/copy.png";
import feedback from "../../image/feedback.png";

const Second = ({ data, issues, setData1, question }) => {
  // console.log(data['brand'])
  // console.log(index)
  // useEffect(()=>{
  //   setIndex(0)
  // })
  const id = useParams();
  const iss = id.issue.replace("_", " ");
  const handleCopy = () => {
    let str = "";
    navigator.clipboard.writeText("");
    data["questionResponse"].forEach((ele) => {
      for (const key in ele) {
        str += "Qr: " + key + "\n" + "Response: " + ele[key] + "\n";
      }
    });
    if (data["sol"] !== "") {
      str += "Reference - " + data["sol"];
    }
    navigator.clipboard.writeText(str);
    alert("Copied to clipboard");
  };
  return (
    <div className="container2">
      <div className="details">
        <div className="data">
          <h5>Brand Name: </h5>
          <h5>{data["brand"]}</h5>
        </div>
        <div className="data">
          <h5>Model No: </h5>
          <h5>{data["model"]}</h5>
        </div>
        <div className="data">
          <h5>Case ID: </h5>
          <h5>{data["case"]}</h5>
        </div>
        <div className="data">
          <h5>Issue: </h5>
          <h5>{iss}</h5>
        </div>
        <div className="data">
          <h5>Product: </h5>
          <h5>{data["product"]}</h5>
        </div>
      </div>
      <div className="flow">
        <div className="queContainer">
          <h2>{iss}</h2>

          <div className="ques">
            <img
              src={copy}
              alt=""
              style={{
                width: "37px",
                height: "37px",
                transition: "transform 0.3s",
              }}
              onMouseOver={(e) => (e.target.style.transform = "scale(1.2)")}
              onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
              onClick={handleCopy}
              title="Copy to Clipboard"
            />
            <a href="https://forms.gle/rosJSk9TTJNcbFBR9" target="_blank">
              {" "}
              {/* Replace "https://example.com/feedback" with the actual link */}
              <img
                src={feedback} // Replace with the image for the feedback button
                alt="Feedback"
                style={{
                  width: "37px",
                  height: "37px",
                  cursor: "pointer",
                  transition: "transform 0.3s",
                }}
                onMouseOver={(e) => (e.target.style.transform = "scale(1.2)")}
                onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
                title="Provide Feedback"
              />
            </a>
          </div>
        </div>
        <hr />
      </div>
      <Condition
        issues={issues}
        data={data}
        setData1={setData1}
        question={question}
        id={id}
      />

      <Outlet />
    </div>
  );
};

export default Second;
