import React, { useEffect, useState } from 'react'
import './Condition.css'
import { useNavigate, useParams,useRoutes } from 'react-router-dom'
import axios from 'axios'
const List = ({question,issues,id,setSol,setData1,value,data,setValue, count , setCount}) => {

  const saveChanges=(id,data)=>{
    let config = {
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
        }
      }
      axios.patch(`https://blitzpath-gtc.el.r.appspot.com/user/${id}`,JSON.stringify(data),config)
      .catch(error=>{
        console.log(error)
      })}
  const navigate = useNavigate()
  const issue = data.issues.replaceAll("_"," ")
  // console.log(question)
  useEffect(() => {
    const handlePopstate = () => {
      // Perform state changes here
      // For example, you can update a state variable or call a function
      setData1({...data, sol:''})
      if(data.questionResponse.length !== 0){
        console.log('remove')
        data.questionResponse.pop()
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);


  const handleChange = (option)=>{
    setValue(option)
    
    setSol(false)
    if(option !== ''){
      // console.log(value)
      const select1 = question[issue][id.id]['options'][option]
      // console.log(select1,option)
      if(select1 !== undefined){
      console.log(select1)
      if  (select1[0] === true){
        document.getElementById('probeButton').innerHTML = 'Submit'

        }
        else{
          document.getElementById('probeButton').innerHTML = 'Next'
        }
      }
      else{
        document.getElementById('probeButton').innerHTML = 'Next'
      }
    }
    else{
      document.getElementById('probeButton').innerHTML = 'Next'
    }      
  }
const handleBack = ()=>{
  setData1({...data, sol:''})
      setCount(count-1)
      if(data.questionResponse.length !== 0){
        console.log('remove')
        data.questionResponse.pop()
      }
      window.history.go(-1)
}

const handleIndex = ()=>{
  saveChanges(data['caseID'],data)
  if(value !== ''){
    const select = question[issue][id.id]['options'][value]
    if(question[issue][parseInt(id.id)]['Que'] !== undefined){
      if(data.questionResponse.length!==0 && data.questionResponse[data.questionResponse.length-1].hasOwnProperty(question[issue][parseInt(id.id)]['Que'])){
        data.questionResponse[data.questionResponse.length-1][question[issue][parseInt(id.id)]['Que']] = value
      }
      else{
      data.questionResponse.push({[question[issue][parseInt(id.id)]['Que']]:value})}
  }
  
  if(select !== undefined){
    
    if (select[0] === true){
      setData1({...data,sol:question[issue][parseInt(id.id)]['options'][value][2]})
      setSol(true)
      setTimeout(() => {
        window.scrollTo(0,9999)
      },500);
    }
    else{
      setCount(count+1)
      setSol(false)
      // console.log(question[ind.issue][id.id].yes)
      // console.log(question[ind.issue][id.id].No[1])
      navigate(`/${issues}/${parseInt(select[1])}`)
      setValue('')
    }
    console.log(data)
    
  }
  }
    
  
}
  return (
    <>
   <div className="boxContainer">
   <div className="description">
   <h2>Step {parseInt(count)+1}: {question[issue][id.id].heading}</h2>
   {question[issue][id.id].subHeading.length !==0 ?
   question[issue][id.id].subHeading.map((ele)=>{
    return(
      <>
      <h3>{ele[0]}</h3>
      <div className="ul">
        <ul>
          {ele[1].map((element,index)=>{
            return(

              <li key={index}>{element}</li>
            )
          })
   }

        </ul>
       {/* {question[id.id].Note.length !==0? <h3>Note: {question[id.id].Note}</h3>: ''}
       {question[id.id].important.length !==0? <h3>Important!: {question[id.id].important}</h3>: ''} */}
      </div>
      </>
    )
   }):
      <div className="ul">
        <ul>
          {question[issue][id.id].desc.map((element,index)=>{
            return(

              <li key={index}>{element}</li>
            )
          })
   }

        </ul>
       {/* {question[id.id].Note.length !==0? <h3>Note: {question[id.id].Note}</h3>: ''}
       {question[id.id].important.length !==0? <h3>Important!: {question[id.id].important}</h3>: ''} */}
      </div>}
   </div>
   
   <div className="question">
     <h3>Q. {question[issue][id.id].Que}</h3>
     
     <div className="answer">
      {/* <div className="ans">
     <label class="rad-label">
    <input type="radio" class="rad-input" value='Yes' onChange={()=>handleChange('yes')} name="rad" checked={checked ? checked.yes : false}/>
    <div class="rad-design"></div>
    <div class="rad-text">Yes</div>
  </label>
  </div> */}
  { 
  question[issue][id.id]['option'].map((key,index)=>{
  // console.log(key === value,value)
  return(
      <div className="ans" key={index}>
  <label className="rad-label">
    <input type="radio" class="rad-input"  onChange={()=>handleChange(key)} value={key} checked={value === key} name="rad" />
    <div className="rad-design"></div>
    <div className="rad-text">{key}</div>
  </label>
  </div>)
  })
}
   </div>
  <div className="button">
  <button
  type="button"
  id="probeButton"
  style={{
    backgroundColor: "#1f2325",
    borderColor: "#1f2325",
    color: "white",
  }}
  onMouseOver={(e) => (e.target.style.backgroundColor = "#0a535f")}
  onMouseOut={(e) => (e.target.style.backgroundColor = "#1f2325")}
  onClick={handleIndex}
>
  Next
</button>

  </div>
 

  <div className="button1">
  <button
  type="button"
  id="probeButton"
  style={{
    backgroundColor: "#1f2325",
    borderColor: "#1f2325",
    color: "white",
  }}
  onMouseOver={(e) => (e.target.style.backgroundColor = "#0a535f")}
  onMouseOut={(e) => (e.target.style.backgroundColor = "#1f2325")}
  onClick={handleBack}
>
  Back
</button>
  </div>
   </div>
   
   </div>
   
   </>
  )
}

export default List
