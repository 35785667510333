import React, { useState } from 'react';
import './Techbook.css'; // Import the CSS file
import jsonData from './techbook.json'; // Import the JSON file

const Techbook = () => {
  const [selectedMenu, setSelectedMenu] = useState(null); // State to track selected main menu
  const [selectedSubMenu, setSelectedSubMenu] = useState(null); // State to track selected submenu
  const [selectedData, setSelectedData] = useState([]); // State to track data for the table

  const menuItems = jsonData.MainMenu; // Access the main menu items from the JSON data

  // Handle main menu selection
  const handleMenuSelect = (menuItem) => {
    setSelectedMenu(menuItem); // Set the selected main menu item
    setSelectedSubMenu(null); // Reset the selected submenu
    setSelectedData([]); // Reset the table data
  };

  // Handle submenu selection
  const handleSubMenuSelect = (subMenuItem) => {
    const data = jsonData[subMenuItem.key] || []; // Access the data based on the submenu key
    setSelectedSubMenu(subMenuItem); // Set the selected submenu item
    setSelectedData(data); // Set the data for the table
  };

  return (
    <div className="techbook">
      {/* Main Menu Section */}
      <div className="menu">
        <h1>Main Menu</h1>
        <ul className="main-menu">
          {menuItems.map((item) => (
            <li key={item.id} onClick={() => handleMenuSelect(item)}>
              {item.title}
            </li>
          ))}
        </ul>
      </div>

      {/* Submenu Section */}
      {selectedMenu && selectedMenu.subMenu && selectedMenu.subMenu.length > 0 && (
        <div className="submenu">
          <h2>{selectedMenu.title}</h2>
          <p>{selectedMenu.instructions}</p>
          <ul className="sub-menu">
            {selectedMenu.subMenu.map((subItem) => (
              <li key={subItem.id} onClick={() => handleSubMenuSelect(subItem)}>
                {subItem.title}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Table Section */}
      <div className="table-container">
        {selectedSubMenu && selectedSubMenu.notes && (
          <div className="notes">
            <strong>Notes:</strong> {selectedSubMenu.notes}
          </div>
        )}
        {selectedData.length > 0 && (
          <table className="data-table">
            <thead>
              <tr>
                <th>Issue Type</th>
                <th>Symptoms 1</th>
                <th>Result</th>
                <th>Symptoms 2</th>
              </tr>
            </thead>
            <tbody>
              {selectedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row.issueType}</td>
                  <td>{row.symptoms1}</td>
                  <td>{row.result}</td>
                  <td>{row.symptoms2}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Techbook;
