import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import './ItemList.css'
import ItemCard from './ItemCard'
import copy from '../../image/copy.png'

const ItemList =({setDisplay})=> {
const param = useParams()
const[itemData,setItemData] = useState()
useEffect(()=>{
    setDisplay('block')
    const getCase = async()=>{
        try{
            const response = await fetch(`https://blitzpath-gtc.el.r.appspot.com/getCase/${param.id}`)
            if(response.ok){
                const data = await response.json()
                console.log(data)
                setItemData(JSON.parse(data))
            }
        }
        catch(error){
            console.log(error)
        }
    }
    getCase()
},[])
const handleCopy = ()=>{
    let str = ''
    str += "UserName: " + itemData['Username'] +'\n'
    navigator.clipboard.writeText("")
    itemData['questionResponse'].forEach(ele => {
      for (const key in ele){
        str += "Que: "+key+'\n'+"Response: "+ele[key]+'\n'
      }
    });
    if(itemData['sol'] !== ""){
      str += "Reference - " + itemData['sol']
    }
    str += "\nTime: " + itemData['working_time']
    navigator.clipboard.writeText(str)
    alert('Copied to clipboard')
  }
  return (<>
    {itemData !== undefined ?
    <div className='itemContainer'>
        <div className="itemDetails">
            <div className="gridTitle">
                <p>Username :</p>
            </div>
            <div className="gridValue">
                <p>{itemData['user']}</p>
            </div>
            <div className="gridTitle">
                <p>Brand :</p>
            </div>
            <div className="gridValue">
                <p>{itemData['brand']}</p>
            </div>
            <div className="gridTitle">
                <p>Id :</p>
            </div>
            <div className="gridValue">
                <p>{itemData['case']}</p>
            </div>
            <div className="gridTitle">
                <p>Issue :</p>
            </div>
            <div className="gridValue">
                <p>{itemData['issues'].replaceAll('_',' ')}</p>
            </div>
            <div className="gridTitle">
                <p>Model :</p>
            </div>
            <div className="gridValue">
                <p>{itemData['model']}</p>
            </div>
            <div className="gridTitle">
                <p>Product Group :</p>
            </div>
            <div className="gridValue">
                <p>{itemData['product']}</p>
            </div>
            <div className="gridTitle">
                <p>Time :</p>
            </div>
            {/* <div className="gridValue">
                <p>{itemData['working_time']}</p>
            </div> */}
        </div>
        <div className="troubleshootingSteps">
            <div className="heading">
                <div className="copy">

                <h2>Troubleshooting Steps</h2>




                <img
  src={copy}
  alt=""
  style={{ width: '37px', height: '37px', transition: 'transform 0.3s' }}
  onMouseOver={(e) => e.target.style.transform = 'scale(1.2)'}
  onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
  onClick={handleCopy}
  title="Copy to Clipboard"
/>







                {/* <img src={copy} alt="" onClick={handleCopy} /> */}
                </div>
                <hr />
            </div>
            <div className="itemList">
                <div className="grid_item">
                    <p>Step</p>
                </div>
                <div className="grid_item">
                    <p>Troubleshooting Question</p>
                </div>
                <div className="grid_item">
                    <p>Response</p>
                </div>
            </div>
            {itemData.questionResponse.map((ele,index)=>{
                return(
                    <ItemCard ele={ele} index={index} key={index}/>
                )
            })}
        </div>
        <div className="ref">
            <h2>Reference</h2>
            <hr />
            <div className="sol">
           {itemData.sol === ''?  <p>Not complete</p>:<p>{itemData.sol}</p>}

            </div>
        </div>
        <div style={{height:'20px'}}></div>
    </div>
    :''}
    </>
  )
}

export default ItemList
