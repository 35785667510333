import React, { useState, useEffect } from 'react';
import './Article.css';

const Article = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Fetching the JSON data from the public folder
    fetch('/article.json')
      .then((response) => response.json())
      .then((jsonData) => setData(jsonData))
      .catch((error) => console.error("Error fetching the data: ", error));
  }, []);

  if (!data) return <div>Loading...</div>; // Show a loader until data is fetched

  return (
    <div className="main_container2">
      {/* Troubleshooting Guidelines Section */}
      <h3 className="heading_column">Troubleshooting Guidelines</h3>
      <div className="troubleshooting_block">
        {data.troubleshootingGuidelines.map((item, index) => (
          <Dropdown key={index} title={item.title} subTitles={item.subTitles} />
        ))}
      </div>

      {/* Info block for Diagnostics and Contact Information */}
      <h3 className="heading_column">Additional Diagnostics And Information</h3>
      <div className="info_block">
        <div className="info_column">
          <h4>Diagnostics</h4>
          {data.diagnostics.map((item, index) => (
            <div key={index}>
              <a href={item.url} target="_blank" rel="noopener noreferrer" className="link_css">
                {item.title}
              </a>
            </div>
          ))}
        </div>

        <div className="info_column">
          <h4>Contact Information</h4>
          {data.contactInformation.map((item, index) => (
            <div key={index}>
              <a href={item.url} target="_blank" rel="noopener noreferrer" className="link_css">
                {item.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Dropdown Component to show Subtitles
const Dropdown = ({ title, subTitles }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="dropdown">
      <h4 onClick={() => setIsOpen(!isOpen)} className="dropdown_title">
        {title}
      </h4>
      {isOpen && (
        <div className="dropdown_content">
          {subTitles.map((sub, index) => (
            <div key={index}>
              <a href={sub.url} target="_blank" rel="noopener noreferrer" className="link_css">
                {sub.name}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Article;
