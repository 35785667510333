import React, { useEffect } from 'react'
import Article from './Article/Article' // Updated import
import './Home.css'

const Home = ({ setIssue, data, setData1, index, setIndex, setDisplay }) => {
  useEffect(() => {
    setDisplay('block')
  }, [setDisplay])

  return (
    <div className='Home'>
      <Article 
        setIssue={setIssue} 
        data={data} 
        setData1={setData1} 
        index={index} 
        setIndex={setIndex} 
      />
    </div>
  )
}

export default Home
