import React, { useContext, useEffect, useState } from "react";
import styles from "./SignUp.module.css";
import { Button, TextField, MenuItem, Select, InputLabel } from "@mui/material";
import styled from "@emotion/styled";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import leftLogo from "../../image/big-logo-png.jpeg";
import { UserContext } from "../../App";

function SignUp() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { setIsAdmin } = useContext(UserContext);

  useEffect(() => {
    // setDisplay("none");
  }, []);
  const WhiteBorderTextField = styled(TextField)`
    & label.Mui-focused {
      color: white;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: white;
      }
    }
  `;

  function validateEmail(username, password, role) {
    var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (username.length === 0) {
      alert("Please enter a valid email!");
      return false;
    }
    else if (username && !username.match(mailFormat)) {
      alert("Invalid email address!");
      return false;
    }
    else if (password.length === 0) {
      alert("Please enter a valid password!");
      return false;
    }
    else if (role === "") {
      alert("Please select a role!");
      return false;
    }
   else {
      // setUsername("");
      return true;
    }
  }
  function handleSubmit(event) {
    event.preventDefault();
    ;
    // url => https://btc-api.blitzpath.in/login
    if(validateEmail(username, password, role) === true) {
      fetch("https://blitzpath-gtc.el.r.appspot.com/registerUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password, role }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("SignUp failed.");
          }
        })
        .then((data) => {
          if (data.success) {
            alert(
              "User register request has been sent successfully to Administrator."
            );
            navigate("/");
          } else {
            setErrorMessage(data.errors[0]);
          }
        })
        .catch((error) => {
          // setErrorMessage(error.message);
          console.log(error, "ioijkjk");
        });
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.bubble1}></div>
      <div className={styles.bubble2}></div>

      <div className={styles.container}>
        <div className={styles.leftWrapper}>
          <div className={styles.leftLogo}>
            <img src={leftLogo} alt="" />
          </div>
          <div className={styles.leftContent}>
            <h2>Welcome</h2>
            <h2>to</h2>
            <h2>Blitzpath Innovations</h2>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.rightContent}>
            <h1>Sign Up</h1>
            <div className={styles.form}>
              <TextField
                label="Username"
                variant="standard"
                className={styles.input}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                autoComplete="current-password"
                variant="standard"
                className={styles.input}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={styles.role}
                value={role}
                label="Role"
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="User">User</MenuItem>
              </Select>
            </div>
            <Button
              variant="contained"
              className={styles.continueBtn}
              endIcon={<NavigateNextIcon m={1} />}
              onClick={(e) => handleSubmit(e)}
            >
              Register
            </Button>
            {errorMessage && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
