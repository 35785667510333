import React from 'react'
import './ItemList.css'
const ItemCard =({ele,index})=> {
  return (
    <>
    <div className="itemValue">
    <div className='grid_value'>
        <p>{index+1}</p>
    </div>
    <div className='grid_value'>
        <p>{Object.keys(ele)[0]}</p>
    </div>
    <div className='grid_value'>
        <p>{ele[Object.keys(ele)[0]]}</p>
    </div>

    </div>
    <hr className='hr'/>
    </>
  )
}

export default ItemCard